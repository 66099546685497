<template>
<div v-on-clickaway="closeColors" class="color-picker-field-comp" :data-active="open">

    <!-- Picker -->
    <div @click="toggleColors" class="picker-container">
        <div class="preview">
            <div class="color-preview mr8" :style="{ 'background-color': currentValue}"></div>
        </div>
        <div class="fieldInput">
            <TextField v-model="currentValue" :data="{}" placeholder="#000000" />
        </div>
    </div>

    <!-- Options -->
    <div class="colors-container">
        <div v-for="option in items" :key="option" @click="select($event.target, option)" class="option" :value="option">
            <span :class="{ 'active' : option === startValue}" class="label" :style="{ 'background': option, 'border-color': option, 'color': option }" ><Icon name="check" /></span>
        </div>        
    </div>



</div>

</template>

<script>
import TextField from './TextField.vue'
import { mixin as clickaway } from 'vue-clickaway';
import Icon from '@/components/Icon.vue';

export default {
  name: 'ColorPickerField',
  mixins: [clickaway],
  components: {
      Icon, TextField
  },
  
  data: function(){
      return {
          currentValue: null,
          open: false
      }
  },

  props: {
    data: Object,
    startValue: String,
    selected: Number,
  },

  computed: {
      items() {
          return ['#ffffff', '#d32f2f', '#E91E63', '#9C27B0', '#673AB7', '#303F9F', '#2979FF', '#039BE5', '#00ACC1', '#00897B', '#43A047', '#7CB342', '#C0CA33', '#FDD835', '#FFB300', '#FB8C00', "#FF5722", "#795548", "#455A64", '#263238', '#ECEFF1']
      }
  },

  mounted () {
      this.currentValue = this.startValue;
  },


  watch: {
    selected: function(){
      this.selecttByArrowKey(this.selected)
    },
    currentValue: function(){
        this.$emit('input', this.currentValue)
    }
  },

  methods: {

      toggleColors(){
          this.open = !this.open
      },

      closeColors(){
          this.open = false
      },


      select(selectedOption, value){

        this.currentValue = value;
        let options = this.$el.querySelectorAll(".option .label");        
        
        options.forEach(function(element){
            element.classList.remove('active');
        })        

        selectedOption.classList.add('active');
        this.open = false
        this.$emit('input', this.currentValue)
      },

      selecttByArrowKey(position){
        let options = this.$el.querySelectorAll(".option");
        this.select(options[position]);
      }

  }

}
</script>

<style scoped lang="sass">

.color-picker-field-comp
    display: inline-flex
    position: relative


.picker-container
    display: flex
    align-items: center

    .color-preview
        width: 34px
        height: 34px
        border-radius: 34px
        border: 2px solid $cG2
        cursor: pointer
        transition: opacity .3s

        &:hover
            transition: opacity .3s
            opacity: .7

    .fieldInput
        max-width: 110px


.colors-container
    width: 160px
    height: 140px
    display: flex
    background: #fff
    padding: $mgXs
    border: 1px solid $cG2
    gap: $mgXxs
    flex-wrap: wrap
    position: absolute
    top: -150px
    right: 0
    box-shadow: 0 0 8px rgba(0,0,0,.2)
    border-radius: 5px
    z-index: 100
    display: none

    [data-active="true"] &
        display: flex


.option
    user-select: none
    box-sizing: border-box
    display: flex
    align-items: center
    justify-content: center
    height: 20px
    font-size: 14px
    
    &:first-child
      .label
        border: 1px solid $cG5!important

        &.active
          color: $cG5!important

    @media (max-width: 500px)
        font-size: $fzXs


    @media (max-width: 800px)
        border-radius: 0
        border-right: 0
        font-size: $fzXs


    .label
        border: 3px solid;
        display: flex
        align-items: center
        justify-content: center
        color: transparent
        height: 20px
        width: 20px
        border-radius: 20px  

        &.active
            background: #fff !important

        i
            pointer-events: none

    
    &:hover
        cursor: pointer
        background: rgba($cBrandA, .1)



</style>
