<template>
  <div v-if="form" class="editor">
      
      <TopHeader nav="Editor" :data="form">
        <Btn @click.native="showPreview(form)" type="secondary" size="sm"><Icon name="eye" /> Ver</Btn>
        <Btn @click.native="openShareLink()" type="outline" size="sm"><Icon name="share-square" type="solid" /></Btn>
        <Btn @click.native="updateFormAndShowLink(form)" size="sm" :loading="working.updateform">Publicar</Btn>
      </TopHeader>

    <!-- <ShareFormModal ref="share-form" :data='{slug: form.slug}' /> -->
    <PublishLinkPopover ref="shareLink" :active="showShareLink" :slug="form.slug" v-on:publish-link-close="closeShareLink"/>



      <main class="page-content">  

        <div class="container container-sm main-content mt64">
            <div class="page-header mb32">
                <h1 class="page-title title-xl mb8">Configurações</h1>
                <p class="page-description title-sm light">
                  <span>Última alteração: {{form.updated_at}}</span>                  
                 </p>
            </div>

            <div class="form">
                <div class="form-group mt16">
                    <label for="teste" class="form-label mb8">Título do formulário:</label>
                    <TextField v-model="form.name" :data={} :value="form.name" slug="title"/>
                </div>


                <div style="display:none" class="form-group mt16">
                    <label for="status" class="form-label mb8">Status:</label>
                    <SelectField
                        v-model="form.status"
                        :value="form.status" 
                        slug="status"
                        :options="[
                            { label: 'Publicado', value: 'publish' },    
                            { label: 'Rascunho', value: 'draft' }    
                        ]" />
                </div>


                <SectionHeader title="Personalizar estilo" description="Adicione o seu logotipo e cores personalizadas." />

                <div class="form-group h-label mt16">
                    <label for="status" class="form-label mb8">Cor do botão:</label>
                    <ColorPickerField2 v-model="form.style.color" :startValue="form.style.color" slug="form-color"/>
                </div>

                <div class="form-group h-label mt16">
                    <label for="status" class="form-label mb8">Cor da pergunta:</label>
                    <ColorPickerField2 v-model="form.style.textColor" :startValue="form.style.textColor" slug="form-text-color"/>
                </div>

                <div class="form-group h-label mt16">
                    <label for="status" class="form-label mb8">Cor da resposta:</label>
                    <ColorPickerField2 v-model="form.style.questionColor" :startValue="form.style.questionColor" slug="form-text-color"/>
                </div>
                
                <div class="form-group h-label mt16">
                    <label for="status" class="form-label mb8">Cor de fundo:</label>
                    <ColorPickerField2 v-model="form.style.bgColor" :startValue="form.style.bgColor" slug="form-bg-color"/>
                </div>

                <div class="form-group mt32">
                    <label for="status" class="form-label mb8">Imagem de fundo:</label><br>
                    <p class="form-legend mb8">Essa imagem irá aparecer no fundo do seu formulário. cuidado com o contraste.</p>
                    <UploadField :form="form" :file="form.style.background" format="image" v-on:upload="uploadFile($event, 'formBackground')" v-on:removed="fileRemoved('formBackground')" />
                </div>

                <div class="form-group mt32">
                    <label for="status" class="form-label mb8">Logotipo</label>
                    <p class="form-legend mb8">Se desejar, adicione um logotipo. Ele aparece no topo do formulário.</p>
                    <UploadField :form="form" :file="form.style.logo" format="image" v-on:upload="uploadFile($event, 'formLogo')" v-on:removed="fileRemoved('formLogo')" />
                </div>

                <div v-if="form.style.logo.path" class="form-group mt32">
                    <label for="status" class="form-label mb8">Posição do logotipo</label>
                    <p class="form-legend mb8">A posição na página onde seu logotipo será exibido.</p>
                    <SelectField :options="logoPositionOptions" v-model="form.style.logo.position" :value="logoPositionOptions[0].value" />
                </div>

                <div class="form-group switch-row mt32">
                    <div class="switch-row-title">
                        <label for="status" class="form-label mb8">Remover a marca Respondi <TagLabel type="new">PRO</TagLabel></label>
                        <SwitchField v-model="removeBrand" :active="removeBrand" :disabled="!canRemoveBrand" />
                    </div>
                    <div class="switch-row-description">
                        <p>Não exibe a nossa mensagem ao final do formulário.</p>
                    </div>
                </div>

                <!-- Notificações -->
                <SectionHeader title="Notificações" description="Seja avisado por e-mail para a cada nova resposta." />
                
                <div class="form-group switch-row mt16">
                    <div class="switch-row-title">
                        <label for="status" class="form-label mb8">Receber alerta por e-mail</label>
                        <SwitchField v-model="form.notifications.owner" :active="form.notifications.owner" />
                    </div>
                    <div class="switch-row-description">
                        <p>Você será alertado a cada formulário completo. Formulários incompletos não são notificados.</p>
                    </div>
                </div>

                <div class="form-group switch-row mt32">
                    <div class="switch-row-title">
                        <label for="status" class="form-label mb8">Notificar quem respondeu</label>
                        <SwitchField v-model="form.notifications.respondent" :active="form.notifications.respondent" />
                    </div>
                    <div class="switch-row-description">
                        <p>Será enviado uma cópia das respostas para a pessoa que respondeu seu formulário caso ela tenha informado um e-mail válido.</p>
                    </div>
                    <div v-if="!hasEmailField && form.notifications.respondent" class="callout mt8">
                        <p>Seu formulário <strong>não possui</strong> nenhum campo do tipo "e-mail" no momento. A notificação não será enviada sem este campo.</p>
                    </div>
                </div>

                <div class="form-group switch-row mt32">
                    <div class="switch-row-title">
                        <label for="status" class="form-label mb8">Encaminhar respostas <TagLabel type="new">PRO</TagLabel></label>
                        <SwitchField v-model="form.notifications.emailForward.active" :active="form.notifications.emailForward.active" :disabled="!canForwardEmail" />
                    </div>
                    <div class="switch-row-description">
                        <p>Será enviado uma cópia das respostas para o endereço de e-mail informado. Você pode adicionar vários endereços separando por vírgula.</p>
                    </div>
                    <div v-if="form.notifications.emailForward.active" class="mt8">
                        <TextField v-model="form.notifications.emailForward.value" :data="{}" placeholder="exemplo@email.com" />
                    </div>
                    <Callout class="mt32" emoji="👀">Procurando <strong>Webhooks</strong>? Nós movemos para a seção de integrações, logo abaixo.</Callout>
                </div>

                <!-- Integrações -->
                <SectionHeader title="Integrações" description="Conecte o seu formulário a centenas de serviços e produtos" />
                <Btn :link="{ name: 'EditorIntegrations', slug: form.slug }" type="secondary" size="sm">Configurar integrações</Btn>

                <!-- Rastreio de UTMs -->
                <SectionHeader title="Rastreio de UTMs" description="Salve automaticamente parametros UTMs adicionados no link do seu formulário" />
                <div class="form-group switch-row mt32 option-utms">
                    <div class="switch-row-title">
                        <label for="status" class="form-label mb8">Salvar parâmetros UTM <TagLabel type="new">PRO</TagLabel></label>
                        <SwitchField v-model="form.options.utms.active" :active="form.options.utms.active" :disabled="!canForwardEmail" />
                        </div>
                        <div class="switch-row-description">
                            <p>Você pode adicionar qualquer um dos parametros <em>utm_source</em>, <em>utm_medium</em>, <em>utm_campaign</em>, <em>utm_term</em> e <em>utm_content</em> no seu link.</p>
                        </div>
                    </div>


                <!-- Acesso -->
                <SectionHeader title="Acesso" description="Defina quando o formulário será fechado para novas respostas." />
                <div class="form-group switch-row mt32">
                    <div class="switch-row-title">
                        <label class="form-label mb8">Bloquear envio de novas respostas</label>
                        <SwitchField v-model="formStatus" :active="formStatus" />
                    </div>
                </div>

                <div v-if="formStatus" class="close-form-option mt32">
                    <div class="form-group">
                        <label class="form-label mb8">Personalizar título</label>
                        <TextField type="url" v-model="form.style.closed.title" :data={} placeholder="EX: UA-0000000-00" slug="gAnalytics"/>
                    </div>

                    <div class="form-group mt32">
                        <label class="form-label mb8">Personalizar descrição</label>
                        <TextField type="url" v-model="form.style.closed.description" :data={} :value="form.style.closed.description" placeholder="EX: UA-0000000-00" slug="close-description"/>
                    </div>

                    <div class="form-group mt32">
                        <label for="status" class="form-label mb8">Imagem</label>
                        <p class="form-legend mb8">Se desejar, adicione uma iamgem ilustrativa.</p>
                        <UploadField v-model="form.style.closed.attachment" :file="form.style.closed.attachment" :form="form" v-on:upload="updateForm(form)" v-on:removed="updateForm(form)" />
                    </div>

                </div>
            </div>



            <!-- Danger Zone -->
            <div class="danger-zone">
                <SectionHeader title="Excluir formulário" description="Esta ação não pode ser desfeita." />
                <Btn @click.native="showDeleteMessage($event.target)" size="sm" type="danger" ref="btnDelete">Excluir formulário</Btn>

                <div class="delete-form-message">
                    Confirme a exclusão de <strong>{{form.name}}</strong>
                    <span class="actions">
                        <Btn @click.native="destroyForm(form)" size="xs" type="danger" class="ml8 mr8">Excluir</Btn>
                        <Btn @click.native="closeDeleteMessage" size="xs" type="outline">cancelar</Btn>
                    </span>
                </div>
            </div>
        </div> 
      </main>

      <footer class="footer"></footer>

  </div>
</template>

<script>
import Callout from '../components/Callout.vue'
import TagLabel from '../components/TagLabel.vue'
import ColorPickerField2 from '../components/form/ColorPickerField2.vue'
import SwitchField from '../components/form/SwitchField.vue'
import UploadField from '../components/form/UploadField.vue'
import PublishLinkPopover from '../components/PublishLinkPopover.vue'
import Btn from '@/components/form/Btn.vue';
import Icon from '@/components/Icon.vue';
import TextField from '@/components/form/TextField.vue';
import ColorPickerField from '@/components/form/ColorPickerField.vue';
import SelectField from '@/components/form/SelectField.vue';
import TopHeader from '@/components/TopHeader.vue';
import SectionHeader from '@/components/SectionHeader.vue';
import { FormMixin } from '@/mixins/FormMixin';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';
// import ShareFormModal from '@/components/modal/ShareFormModal.vue';



// const axios = require('axios').default;

export default {
  name: 'EditorAnswers',
  mixins: [FormMixin, UtilitiesMixin],
  components: {
    TextField,
    ColorPickerField,
    SelectField,
    TopHeader,
    Btn,
    SectionHeader,
    Icon,
    // ShareFormModal,
    PublishLinkPopover, UploadField, SwitchField, ColorPickerField2, TagLabel, Callout
  },

  data() {
      return {
        showShareLink: false,
        showModal: {
            webhooks: false
        },
        logoPositionOptions: [
            {label: "Esquerda", value: "left"},
            {label: "Centro", value: "center"},
            {label: "Direita", value: "right"},
        ]
      }
  },

  computed: {
      hasEmailField() {
          let result = false
          this.form.fields.forEach(field => {
              if(field.type == "email") result = true
          })
          return result
      },

      canRemoveBrand(){
          return this.$user.limits.feature.remove_brand        
      },

    canForwardEmail(){
        if(this.$user.plan_level > 2) return true
        return false
    },

    removeBrand: {
        get: function(){
        if(this.$user.plan_level < 3) return false
        return this.form.style.removeBrand
        },

        set: function(value){
            this.form.style.removeBrand = value              
        }
    },
    formStatus: {
        get: function(){
            if(this.form.status == "closed") return true
            return false
        },

        set: function(value){
            if(value == true) this.form.status = "closed"
            else this.form.status = "draft"
        }
    }
  },

  methods: {

    openModal(modal){
        if(modal == 'webhooks') this.showModal.webhooks = true
    },

    fileRemoved(notify){
        if(notify == 'formBackground') this.form.style.background = {path: null}
        if(notify == 'formLogo') this.form.style.logo = {path: null}
        this.updateForm(this.form)
    },

    uploadFile(data, notify){
        const fileData = {
            filename: data.filename,
            size: data.size,
            path: data.path,
            id: data.id,
        }

        if(notify == 'formBackground') this.form.style.background = fileData
        if(notify == 'formLogo') this.form.style.logo = fileData
        this.updateForm(this.form)        
    },



    upload(event, name){
        if(event.target.files.length > 0) this.uploadAsset(event.target.files[0], name)
        else console.log("No file")
    },



    updateFormAndShowLink(){
        if(this.form && this.removeBrand == false) this.form.style.removeBrand = false
        this.updateForm(this.form)
        .then(data => {
            this.openShareLink()
        })
    },


     openShareLink(){
        this.showShareLink = true
    },


    closeShareLink(){
        this.showShareLink = false
    },


      showDeleteMessage(element) {
          element.parentElement.setAttribute('data-hide', null)
          const message = document.querySelector('.delete-form-message')
          message.setAttribute('data-show', null)

      },

      closeDeleteMessage(){
          const message = document.querySelector('.delete-form-message')
          const btn = this.$refs.btnDelete.$el
          btn.removeAttribute('data-hide')
          message.removeAttribute('data-show')
      },


  },
  
}

</script>

<style lang="sass">

[data-disabled="true"]
    opacity: .6
    pointer-events: none

.h-label
    display: flex
    align-items: center
    justify-content: space-between

.switch-row

    .switch-row-title
        display: flex
        justify-content: space-between
        font-size: $fzSm
        align-items: center
    
    .switch-row-description
        font-size: $fzXs * .9
        line-height: 140%

.form-label
    display: inline-block


.form-legend
    font-size: $fzXxs

.footer
    padding: $mg 0

.delete-form-message
    padding: $mgXs $mgXs
    border-radius: 4px
    background: $cG1
    display: none


[data-show]
    display: block

[data-hide]
    display: none !important

.callout
    padding: $mgSm
    font-size: $fzXs
    background: rgba($cNegative, .1)
    border: 1px solid rgba($cNegative, .3)
    border-radius: 4px
    color: darken($cNegative, 20%)
    line-height: 130%

    a
        text-decoration: underline
        font-weight: bold
        color: darken($cNegative, .9)




</style>
