<template>
  <div class="field-group colorpicker-container">

    <div v-for="option in items" :key="option" @click="select($event.target, option)" class="option" :value="option">
        <span :class="{ 'active' : option === startValue}" class="label" :style="{ 'background': option, 'border-color': option, 'color': option }" ><Icon name="check" /></span>
    </div>
        
  </div>

</template>

<script>

import Icon from '@/components/Icon.vue';

export default {
  name: 'ColorPickerField',
  components: {
      Icon,
  },
  
  data: function(){
      return {
          currentValue: null,
      }
  },

  props: {
    data: Object,
    startValue: String,
    selected: Number,
  },

  computed: {
      items() {
          return ['#ffffff', '#d32f2f', '#E91E63', '#9C27B0', '#673AB7', '#303F9F', '#2979FF', '#039BE5', '#00ACC1', '#00897B', '#43A047', '#7CB342', '#C0CA33', '#FDD835', '#FFB300', '#FB8C00', "#FF5722", "#795548", "#455A64", '#263238', '#ECEFF1']
      }
  },


  watch: {
    selected: function(){
      this.selecttByArrowKey(this.selected)
    },
  },

  methods: {


      select(selectedOption, value){

        this.currentValue = value;
        let options = this.$el.querySelectorAll(".option .label");        
        
        options.forEach(function(element){
            element.classList.remove('active');
        })        

        selectedOption.classList.add('active');
        this.$emit('input', this.currentValue)
      },

      selecttByArrowKey(position){
        let options = this.$el.querySelectorAll(".option");
        this.select(options[position]);
      }

  }

}
</script>

<style scoped lang="sass">

.colorpicker-container
    width: 100%
    display: flex
    gap: $mgXxs
    flex-wrap: wrap


.option
    user-select: none
    box-sizing: border-box
    display: flex
    align-items: center
    justify-content: center
    height: 20px
    font-size: 14px
    
    &:first-child
      .label
        border: 1px solid $cG5!important

        &.active
          color: $cG5!important

    @media (max-width: 500px)
        font-size: $fzXs


    @media (max-width: 800px)
        border-radius: 0
        border-right: 0
        font-size: $fzXs


    .label
        border: 3px solid;
        display: flex
        align-items: center
        justify-content: center
        color: transparent
        height: 20px
        width: 20px
        border-radius: 20px  

        &.active
            background: #fff !important

        i
            pointer-events: none

    
    &:hover
        cursor: pointer
        background: rgba($cBrandA, .1)



</style>
